/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CmsBanner_viewer$ref = any;
type Freebie_product$ref = any;
type List_viewer$ref = any;
type PriceMatch_product$ref = any;
type PriceMatch_viewer$ref = any;
type ProductStock_product$ref = any;
type ProductStock_viewer$ref = any;
type Related_product$ref = any;
type Related_viewer$ref = any;
type ReviewForm_product$ref = any;
type ReviewList_product$ref = any;
type ReviewList_viewer$ref = any;
type ShippingQuote_viewer$ref = any;
type TierPrice_product$ref = any;
type WishlistBtn_product$ref = any;
type WishlistBtn_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProductView_viewer$ref: FragmentReference;
declare export opaque type ProductView_viewer$fragmentType: ProductView_viewer$ref;
export type ProductView_viewer = {|
  +email: ?string,
  +salesNumber: ?string,
  +freeShippingAmount: ?number,
  +products: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +alerts: ?any,
        +name: ?string,
        +type: ?string,
        +brand: ?{|
          +id: string,
          +imageUrl: ?string,
          +name: ?string,
          +urlSlug: ?string,
        |},
        +mainImage: ?{|
          +id: string,
          +url: ?string,
          +thumbnail: ?string,
        |},
        +files: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +url: ?string,
            |}
          |}>
        |},
        +images: ?$ReadOnlyArray<?{|
          +url: ?string,
          +thumbnail: ?string,
        |}>,
        +model: ?string,
        +bundles: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +model: ?string,
              +sku: ?string,
              +ac: ?string,
              +description: ?string,
              +specification: ?string,
              +warranty: ?string,
              +urlSlug: ?string,
              +quantity: ?number,
              +images: ?$ReadOnlyArray<?{|
                +url: ?string,
                +thumbnail: ?string,
              |}>,
            |}
          |}>
        |},
        +configurableAttributes: ?any,
        +configurables: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +type: ?string,
              +attributes: ?any,
              +model: ?string,
              +secondModel: ?string,
              +sku: ?string,
              +ac: ?string,
              +regularPrice: ?number,
              +price: ?number,
              +priceMatch: ?boolean,
              +description: ?string,
              +specification: ?string,
              +warranty: ?string,
              +urlSlug: ?string,
              +quantity: ?number,
              +sellable: ?boolean,
              +nonStock: ?boolean,
              +onlineOnly: ?boolean,
              +clearance: ?boolean,
              +callForPrice: ?boolean,
              +inStoreOnly: ?boolean,
              +sortingFactor: ?number,
              +videos: ?$ReadOnlyArray<?string>,
              +backInStockDate: ?string,
              +stockAvailable: ?number,
              +preorderDate: ?string,
              +globalFreeShipping: ?boolean,
              +freeShipping: ?boolean,
              +tierPrices: ?any,
              +discountCode: ?any,
              +loyaltyPoints: ?{|
                +accruing: ?number,
                +dollars: ?number,
              |},
              +images: ?$ReadOnlyArray<?{|
                +url: ?string,
                +thumbnail: ?string,
              |}>,
              +metaDescription: ?string,
              +status: ?string,
              +labels: ?{|
                +edges: ?$ReadOnlyArray<?{|
                  +node: ?{|
                    +id: string,
                    +imageUrl: ?string,
                    +productPageImageUrl: ?string,
                    +placement: ?string,
                  |}
                |}>
              |},
              +bonusProducts: ?{|
                +edges: ?$ReadOnlyArray<?{|
                  +node: ?{|
                    +productId: ?string,
                    +productName: ?string,
                    +productUrlSlug: ?string,
                    +price: ?number,
                    +quantity: ?number,
                    +optional: ?boolean,
                  |}
                |}>
              |},
              +$fragmentRefs: TierPrice_product$ref & PriceMatch_product$ref & ReviewForm_product$ref & ReviewList_product$ref & ProductStock_product$ref & Freebie_product$ref,
            |}
          |}>
        |},
        +secondModel: ?string,
        +sortingFactor: ?number,
        +sku: ?string,
        +ac: ?string,
        +description: ?string,
        +specification: ?string,
        +urlSlug: ?string,
        +regularPrice: ?number,
        +price: ?number,
        +nonStock: ?boolean,
        +onlineOnly: ?boolean,
        +earnPoints: ?boolean,
        +videos: ?$ReadOnlyArray<?string>,
        +backInStockDate: ?string,
        +stockAvailable: ?number,
        +preorderDate: ?string,
        +globalFreeShipping: ?boolean,
        +freeShipping: ?boolean,
        +sellable: ?boolean,
        +clearance: ?boolean,
        +callForPrice: ?boolean,
        +inStoreOnly: ?boolean,
        +tierPrices: ?any,
        +warranty: ?string,
        +discountCode: ?any,
        +loyaltyPoints: ?{|
          +accruing: ?number,
          +dollars: ?number,
        |},
        +metaDescription: ?string,
        +status: ?string,
        +labels: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +imageUrl: ?string,
              +productPageImageUrl: ?string,
              +placement: ?string,
            |}
          |}>
        |},
        +bonusProducts: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +productId: ?string,
              +productName: ?string,
              +productUrlSlug: ?string,
              +price: ?number,
              +quantity: ?number,
              +optional: ?boolean,
            |}
          |}>
        |},
        +suggestions: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +alerts: ?any,
              +name: ?string,
              +model: ?string,
              +secondModel: ?string,
              +price: ?number,
              +sku: ?string,
              +ac: ?string,
              +urlSlug: ?string,
              +freeShipping: ?boolean,
              +preorderDate: ?string,
              +backInStockDate: ?string,
              +callForPrice: ?boolean,
              +inStoreOnly: ?boolean,
              +onlineOnly: ?boolean,
              +discountCode: ?any,
              +nonStock: ?boolean,
              +sellable: ?boolean,
              +clearance: ?boolean,
              +brand: ?{|
                +id: string,
                +name: ?string,
              |},
              +labels: ?{|
                +edges: ?$ReadOnlyArray<?{|
                  +node: ?{|
                    +id: string,
                    +type: ?number,
                    +imageUrl: ?string,
                    +placement: ?string,
                  |}
                |}>
              |},
              +mainImage: ?{|
                +id: string,
                +thumbnail: ?string,
              |},
            |}
          |}>
        |},
        +$fragmentRefs: TierPrice_product$ref & PriceMatch_product$ref & ReviewForm_product$ref & ReviewList_product$ref & ProductStock_product$ref & Freebie_product$ref & Related_product$ref & WishlistBtn_product$ref,
      |}
    |}>
  |},
  +$fragmentRefs: CmsBanner_viewer$ref & ShippingQuote_viewer$ref & ReviewList_viewer$ref & List_viewer$ref & PriceMatch_viewer$ref & ProductStock_viewer$ref & Related_viewer$ref & WishlistBtn_viewer$ref,
  +$refType: ProductView_viewer$ref,
|};
export type ProductView_viewer$data = ProductView_viewer;
export type ProductView_viewer$key = {
  +$data?: ProductView_viewer$data,
  +$fragmentRefs: ProductView_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "alerts",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlSlug",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnail",
  "storageKey": null
},
v8 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 99
  }
],
v9 = [
  (v6/*: any*/),
  (v7/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "model",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sku",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ac",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "specification",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "warranty",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "showInKit",
      "value": true
    }
  ],
  "concreteType": "ProductImage",
  "kind": "LinkedField",
  "name": "images",
  "plural": true,
  "selections": (v9/*: any*/),
  "storageKey": "images(showInKit:true)"
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "secondModel",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "regularPrice",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sellable",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nonStock",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "onlineOnly",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clearance",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "callForPrice",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "inStoreOnly",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sortingFactor",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "videos",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "backInStockDate",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stockAvailable",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "preorderDate",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "globalFreeShipping",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "freeShipping",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tierPrices",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discountCode",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "concreteType": "LoyaltyPoints",
  "kind": "LinkedField",
  "name": "loyaltyPoints",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accruing",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dollars",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "metaDescription",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v39 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 999
  }
],
v40 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "placement",
  "storageKey": null
},
v41 = {
  "alias": null,
  "args": (v39/*: any*/),
  "concreteType": "LabelConnection",
  "kind": "LinkedField",
  "name": "labels",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LabelEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Label",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "productPageImageUrl",
              "storageKey": null
            },
            (v40/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "labels(first:999)"
},
v42 = {
  "alias": null,
  "args": (v39/*: any*/),
  "concreteType": "BonusProductConnection",
  "kind": "LinkedField",
  "name": "bonusProducts",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BonusProductEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BonusProduct",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "productId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "productName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "productUrlSlug",
              "storageKey": null
            },
            (v20/*: any*/),
            (v16/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "optional",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "bonusProducts(first:999)"
},
v43 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "TierPrice_product"
},
v44 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "PriceMatch_product"
},
v45 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "ReviewForm_product"
},
v46 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "ReviewList_product"
},
v47 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "ProductStock_product"
},
v48 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "Freebie_product"
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "urlSlugs"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductView_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salesNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "freeShippingAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Variable",
          "name": "urlSlugs",
          "variableName": "urlSlugs"
        }
      ],
      "concreteType": "ProductConnection",
      "kind": "LinkedField",
      "name": "products",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Product",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Brand",
                  "kind": "LinkedField",
                  "name": "brand",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v4/*: any*/),
                    (v2/*: any*/),
                    (v5/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProductImage",
                  "kind": "LinkedField",
                  "name": "mainImage",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v6/*: any*/),
                    (v7/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": (v8/*: any*/),
                  "concreteType": "ProductFileConnection",
                  "kind": "LinkedField",
                  "name": "files",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProductFileEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProductFile",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v2/*: any*/),
                            (v6/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "files(first:99)"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProductImage",
                  "kind": "LinkedField",
                  "name": "images",
                  "plural": true,
                  "selections": (v9/*: any*/),
                  "storageKey": null
                },
                (v10/*: any*/),
                {
                  "alias": null,
                  "args": (v8/*: any*/),
                  "concreteType": "ProductConnection",
                  "kind": "LinkedField",
                  "name": "bundles",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProductEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Product",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v2/*: any*/),
                            (v10/*: any*/),
                            (v11/*: any*/),
                            (v12/*: any*/),
                            (v13/*: any*/),
                            (v14/*: any*/),
                            (v15/*: any*/),
                            (v5/*: any*/),
                            (v16/*: any*/),
                            (v17/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "bundles(first:99)"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "configurableAttributes",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": (v8/*: any*/),
                  "concreteType": "ProductConnection",
                  "kind": "LinkedField",
                  "name": "configurables",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProductEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Product",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v2/*: any*/),
                            (v3/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "attributes",
                              "storageKey": null
                            },
                            (v10/*: any*/),
                            (v18/*: any*/),
                            (v11/*: any*/),
                            (v12/*: any*/),
                            (v19/*: any*/),
                            (v20/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "priceMatch",
                              "storageKey": null
                            },
                            (v13/*: any*/),
                            (v14/*: any*/),
                            (v15/*: any*/),
                            (v5/*: any*/),
                            (v16/*: any*/),
                            (v21/*: any*/),
                            (v22/*: any*/),
                            (v23/*: any*/),
                            (v24/*: any*/),
                            (v25/*: any*/),
                            (v26/*: any*/),
                            (v27/*: any*/),
                            (v28/*: any*/),
                            (v29/*: any*/),
                            (v30/*: any*/),
                            (v31/*: any*/),
                            (v32/*: any*/),
                            (v33/*: any*/),
                            (v34/*: any*/),
                            (v35/*: any*/),
                            (v36/*: any*/),
                            (v17/*: any*/),
                            (v37/*: any*/),
                            (v38/*: any*/),
                            (v41/*: any*/),
                            (v42/*: any*/),
                            (v43/*: any*/),
                            (v44/*: any*/),
                            (v45/*: any*/),
                            (v46/*: any*/),
                            (v47/*: any*/),
                            (v48/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "configurables(first:99)"
                },
                (v18/*: any*/),
                (v27/*: any*/),
                (v11/*: any*/),
                (v12/*: any*/),
                (v13/*: any*/),
                (v14/*: any*/),
                (v5/*: any*/),
                (v19/*: any*/),
                (v20/*: any*/),
                (v22/*: any*/),
                (v23/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "earnPoints",
                  "storageKey": null
                },
                (v28/*: any*/),
                (v29/*: any*/),
                (v30/*: any*/),
                (v31/*: any*/),
                (v32/*: any*/),
                (v33/*: any*/),
                (v21/*: any*/),
                (v24/*: any*/),
                (v25/*: any*/),
                (v26/*: any*/),
                (v34/*: any*/),
                (v15/*: any*/),
                (v35/*: any*/),
                (v36/*: any*/),
                (v37/*: any*/),
                (v38/*: any*/),
                (v41/*: any*/),
                (v42/*: any*/),
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "first",
                      "value": 12
                    }
                  ],
                  "concreteType": "ProductConnection",
                  "kind": "LinkedField",
                  "name": "suggestions",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProductEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Product",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/),
                            (v2/*: any*/),
                            (v10/*: any*/),
                            (v18/*: any*/),
                            (v20/*: any*/),
                            (v11/*: any*/),
                            (v12/*: any*/),
                            (v5/*: any*/),
                            (v33/*: any*/),
                            (v31/*: any*/),
                            (v29/*: any*/),
                            (v25/*: any*/),
                            (v26/*: any*/),
                            (v23/*: any*/),
                            (v35/*: any*/),
                            (v22/*: any*/),
                            (v21/*: any*/),
                            (v24/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Brand",
                              "kind": "LinkedField",
                              "name": "brand",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                (v2/*: any*/)
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": (v39/*: any*/),
                              "concreteType": "LabelConnection",
                              "kind": "LinkedField",
                              "name": "labels",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "LabelEdge",
                                  "kind": "LinkedField",
                                  "name": "edges",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "Label",
                                      "kind": "LinkedField",
                                      "name": "node",
                                      "plural": false,
                                      "selections": [
                                        (v0/*: any*/),
                                        (v3/*: any*/),
                                        (v4/*: any*/),
                                        (v40/*: any*/)
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": "labels(first:999)"
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ProductImage",
                              "kind": "LinkedField",
                              "name": "mainImage",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                (v7/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "suggestions(first:12)"
                },
                (v43/*: any*/),
                (v44/*: any*/),
                (v45/*: any*/),
                (v46/*: any*/),
                (v47/*: any*/),
                (v48/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "Related_product"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "WishlistBtn_product"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CmsBanner_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ShippingQuote_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReviewList_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "List_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PriceMatch_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductStock_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Related_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WishlistBtn_viewer"
    }
  ],
  "type": "Customer",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e06ea6dca877683437f0d2060833d0a7';

module.exports = node;
