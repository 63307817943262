import Cms from './Cms';
import CmsPage from './CmsPage';
import CareerPage from './CareerPage';
import Covid19Page from './Covid19Page';
import CustomerOrderForm from './CustomerOrderForm';
import FeedbackPage from './FeedbackPage';
import ThreeMPage from './ThreeMPage';
import TocPage from './TocPage';
import MacnaughtPage from './MacnaughtPage';
import PriorityPage from './PriorityPage';
import SptoolsPage from './SptoolsPage';
import SupplierForm from './SupplierForm';
import ZipPage from './ZipPage';

module.exports = {
  Cms,
  CmsPage,
  CareerPage,
  Covid19Page,
  CustomerOrderForm,
  FeedbackPage,
  MacnaughtPage,
  PriorityPage,
  SptoolsPage,
  ThreeMPage,
  TocPage,
  SupplierForm,
  ZipPage
};
