import React from 'react';
import PropTypes from 'prop-types';

const hasPriceDrop = (product) => (product.regularPrice && product.regularPrice > product.price) && product.discountCode === null;

export const PriceDrop = (props) => {
  const { product, style={} } = props;

  const defaultStyle = {
    backgroundColor: 'green',
    color: 'white',
    display: 'table',
    fontWeight: 900,
    fontSize: '9px',
    padding: '0 2px',
    marginLeft: 'auto',
    textAlign: 'center',
    whiteSpace: 'nowrap',
  };

  if (hasPriceDrop(product)) {
    let pctOff = '';

    const pct = Math.abs((product.price - product.regularPrice) / product.regularPrice) * 100.0;
    if (pct > 0.0) {
      pctOff = `${Math.floor(pct)}% `;
    }

    return (
      <div style={{...defaultStyle, ...style}}>
        <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
          {pctOff} PRICE DROP
        </div>
      </div>
    );
  }

  return null;
}

PriceDrop.hasPriceDrop = hasPriceDrop;

PriceDrop.propTypes = {
  product: PropTypes.shape({
    discountCode: PropTypes.shape({
      type: PropTypes.number,
      amount: PropTypes.number,
    }),
    regularPrice: PropTypes.number,
    price: PropTypes.number,
  }).isRequired,
  style: PropTypes.shape({
  }),
};
PriceDrop.defaultProps = {
  style: {},
};

export const splitPrice = (price, priceStyles) => {
 if (price) {
   price = price.toFixed(2).split(".");

   const defaultStyles = {
     dollar: {
       fontSize: '36px',
     },
     integer: {
       fontSize: '60px',
     },
     decimal: {
       fontSize: '28px',
     },
   };

   const styles = Object.assign(defaultStyles, priceStyles);

   return (
     <React.Fragment>
       <span style={{fontWeight: '900', fontSize: styles.dollar.fontSize, verticalAlign: '13%'}}>$</span>
       <span style={{fontWeight: '900', fontSize: styles.integer.fontSize}}>{price[0]}</span>
       <span style={{fontWeight: '900', fontSize: styles.decimal.fontSize, verticalAlign: '55%'}} className="price-cents">.</span>
       <span style={{fontWeight: '900', fontSize: styles.decimal.fontSize, verticalAlign: '55%'}} className="price-cents">{price[1]}</span>
     </React.Fragment>
   );

 }

 return null;
};

export const renderPrice = (product, {price, priceStyles, color, showNormally, page}) => (
  <div>
    <div style={{height: '19px'}}>
      {showNormally &&
      ((product.regularPrice && product.regularPrice !== product.price) || product.discountCode) && (
        <div
          style={{
            fontSize: '13px',
            color: 'grey',
          }}
        >
          Normally ${product.discountCode ? product.price.toFixed(2) : product.regularPrice.toFixed(2)}
        </div>
        )
      }
    </div>
    <div
      className="price"
      style={{ fontWeight: 'bold', color }}
    >
      {splitPrice(price, priceStyles)}
      {page === "productview" && process.env.COUNTRY === 'NZ' && <small><b>Incl GST</b></small>}
    </div>
  </div>
)

function Price({product, priceStyles, showNormally, showImage = true, page}) {
  if (!product.callForPrice) {
    let { price } = product;
    let color = 'default';

    if (product.discountCode) {
      const discount = product.discountCode;
      color = 'green';

      if (discount.type === 1) {
        price = product.price * (1 - discount.amount / 100);
      }
      else {
        price = product.price - discount.amount;
      }
    }

    if (product.regularPrice && product.regularPrice !== product.price) {
      color = 'green';
    }

    if (product.discountCode && showNormally) {
      return renderPrice(product, {price, priceStyles, color, showNormally, page});
    }

    if ((product.regularPrice && product.regularPrice > product.price) && showNormally) {
      return (
        <div>
          {showImage && (
          <PriceDrop
            product={product}
            style={{
              width: '200px',
              height: '37px',
              fontSize: '20px',
              marginLeft: 'none',
              padding: '0 2px',
              textAlign: 'center',
            }}
          />
          )}
          {renderPrice(product, {price, priceStyles, color, showNormally, page})}
        </div>
      )
    }

    return (
      <div>
        {renderPrice(product, {price, priceStyles, color, page})}
      </div>
    )
  }

  return null;
}

Price.propTypes = {
  product: PropTypes.shape({
    discountCode: PropTypes.shape({
      type: PropTypes.number,
      amount: PropTypes.number,
    }),
    callForPrice: PropTypes.bool,
    regularPrice: PropTypes.number,
    price: PropTypes.number,
  }).isRequired,
  priceStyles: PropTypes.shape({
  }),
  showNormally: PropTypes.bool,
  showImage: PropTypes.bool,
  page: PropTypes.string,
};

Price.defaultProps = {
  priceStyles: {},
  showNormally: true,
  showImage: true,
  page: null,
};

export default Price;
