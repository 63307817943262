import React from 'react';
import {
  createPaginationContainer,
  graphql,
} from 'react-relay';

import PropTypes from 'prop-types';
import { get } from 'lodash';
import { NotFoundConent } from '~/components/error/NotFound';
import { showFilters } from '~/helper';

import List from './List';
import FilterList from './FilterList';
import PreviewDescription from './PreviewDescription';
import ProductList from '../product/ProductList';

class Catalogue extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      catalogues: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      categories: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    match: PropTypes.shape({
      location: PropTypes.shape({
        query: PropTypes.shape({
          filters: PropTypes.string,
        }).isRequired,
        pathname: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }

  render() {
    const { viewer } = this.props;
    const category = get(viewer, 'categories.edges[0].node', {});
    const catalogue = get(category, 'catalogues.edges[0].node', {}) || get(viewer, 'catalogues.edges[0].node', {});
    const catalogues = get(category, 'catalogues', {}) || get(viewer, 'catalogues', {});
    const facets = get(catalogues, 'facets', []);
    const filters = showFilters(catalogues, this.props.match.location.query.filters);

    const jsonLd = category.jsonLd && (<script type='application/ld+json' dangerouslySetInnerHTML={{ __html: category.jsonLd}} />);

    if (category.id && category.previewDescription !== null) {
      return (
        <div>
          {jsonLd}
          <PreviewDescription {...this.props} />
        </div>
      )
    } else if (category.id) {
      return (
        <div>
          {jsonLd}

          {filters.length > 0 && (
          <FilterList {...this.props}  filters={filters} />
          )}

          {(filters.length === 0  && (catalogue.__typename === 'Product' || facets.length !== 0)) && (
          <ProductList {...this.props} catalogues={catalogues} />
          )}

          {(filters.length === 0 && (catalogue.__typename !== 'Product' && facets.length === 0)) && (
          <List {...this.props} />
          )}
        </div>
      );
    }

    return NotFoundConent;
  }
}

export default createPaginationContainer(
  Catalogue,
  {
    viewer: graphql`
    fragment Catalogue_viewer on Customer @argumentDefinitions(
      count: {type: "Int!"}
      cursor: {type: "String"}
      filters: {type: "String"}
      orderBy: {type: "OrderBy"}
      categoryUrlSlug: {type: "String"}
      brandUrlSlug: {type: "String", defaultValue: ""}
      subcategoryUrlSlug: {type: "String", defaultValue: ""}
      subcategoryUrlSlug2: {type: "String", defaultValue: ""}
      page: {type: "Int"}
      priorityPage: {type: "Boolean", defaultValue: false}
    ) {
      ...List_viewer
      ...CmsBanner_viewer
      ...PriorityPage_viewer @include(if: $priorityPage)
      configs
      categories(first: 1, urlSlug: $categoryUrlSlug) {
        edges {
          node {
            faqs(first: 99) {
              edges {
                node {
                  id
                  question
                  answer
                }
              }
            }
            id
            name
            urlSlug
            description
            description2
            metaTitle
            metaDescription
            previewDescription
            jsonLd
            featuredBrands(first: 999) {
              edges {
                node {
                  id
                  name
                  imageUrl
                  urlSlug
                }
              }
            }
            catalogues(
              first: $count,
              orderBy: $orderBy,
              after: $cursor,
              filters: $filters,
              brandUrlSlug: $brandUrlSlug,
              subcategoryUrlSlug: $subcategoryUrlSlug
              subcategoryUrlSlug2: $subcategoryUrlSlug2
              page: $page
            ) @connection(key: "Catalogue_catalogues") {
              totalCount
              subcategory {
                urlSlug
                name
                canonicalUrl
                metaTitle
                metaDescription
                description
                description2
                faqs(first: 99) {
                  edges {
                    node {
                      id
                      question
                      answer
                    }
                  }
                }
              }
              brand {
                urlSlug
                metaTitle
                description
                description2
                name
              }
              facets {
                ...FacetsFragment_facet @relay(mask: false)
              }
              edges {
                node {
                  ... on Subcategory {
                    id
                    imageUrl
                    imageMode
                    link
                    displayMode
                    name
                    position
                    urlSlug
                    docCount
                    metaTitle
                    metaDescription
                  }
                  ...on Product {
                    id
                    name
                    adjacentBanners(first: 99) {
                      edges {
                        node {
                          id
                          name
                          placement
                          imageUrl
                          link
                        }
                      }
                    }
                    ...ProductCard_product
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.viewer.categories && props.viewer.categories.edges[0].node.catalogues;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return {
        count,
        cursor,
        filters: fragmentVariables.filters,
        orderBy: fragmentVariables.orderBy,
        categoryUrlSlug: fragmentVariables.categoryUrlSlug,
        subcategoryUrlSlug: fragmentVariables.subcategoryUrlSlug,
        subcategoryUrlSlug2: fragmentVariables.subcategoryUrlSlug2,
        brandUrlSlug: fragmentVariables.brandUrlSlug,
      };
    },
    query: graphql`
    query CataloguePaginationQuery (
      $count: Int!
      $cursor: String
      $filters: String
      $orderBy: OrderBy
      $categoryUrlSlug: String!
      $subcategoryUrlSlug: String
      $subcategoryUrlSlug2: String
      $brandUrlSlug: String
      $page: Int
    ) {
      viewer {
        ...Catalogue_viewer @arguments(
          categoryUrlSlug: $categoryUrlSlug,
          subcategoryUrlSlug: $subcategoryUrlSlug,
          subcategoryUrlSlug2: $subcategoryUrlSlug2,
          brandUrlSlug: $brandUrlSlug,
          count: $count,
          cursor: $cursor,
          filters: $filters,
          orderBy: $orderBy,
          page: $page
        )
      }
    }
  `,
  },
);
