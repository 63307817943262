import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import MediaQuery from 'react-responsive';

import { ShoppingCartOutlined } from '@ant-design/icons';
import { Badge, Dropdown } from 'antd';
import { get } from 'lodash';

import 'antd/lib/badge/style';
import './style.css';

import ItemList from './ItemList';
import CartUpdatedSubscription from './susbscriptions/CartUpdatedSubscription';

class CartView extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      cart: PropTypes.shape({
        subtotal: PropTypes.number.isRequired,
        lines: PropTypes.shape({
          edges: PropTypes.arrayOf(PropTypes.object),
        }).isRequired,
      }),
    }).isRequired,
    bodyStyle: PropTypes.objectOf(PropTypes.string),
  };

  static defaultProps = {
    bodyStyle: {},
  }

  constructor(props) {
    super(props);

    let subscribed = false;
    if (props.viewer.cart) {
      this.subscribeCart(props);
      subscribed = true;
    }

    this.state = {
      subscribed,
      visible: false,
    };
  }

  /*
   * Cart can be null on initial load (cart not subscribed),
   * Only Subscribe to cart when user add item to cart.
   */
  componentDidUpdate(prevProps, prevState) {
    if (!prevState.subscribed && prevProps.viewer.cart) {
      this.setState({ // eslint-disable-line react/no-did-update-set-state
        subscribed: true,
      }, () => {
        this.subscribeCart(prevProps);
      });
    }
  }

  getTotalItem = (cart) => {
    const cartEdge = get(cart, 'lines.edges', []);

    return cartEdge.reduce((acc, currValue) => acc + currValue.node.quantity, 0);
  }

  subscribeCart = (props) => {
     const { relay: { environment }, viewer: { cart } } = props;

     const cartId = get(cart, 'id');

     if (cartId) {
       CartUpdatedSubscription(environment, {id: cartId});
     }
  }

  handleMenuClick = () => {
    this.setState({ visible: false });
  }

  handleVisibleChange = (flag) => {
    this.setState({ visible: flag });
  }

  render() {
    const { viewer } = this.props;
    const cart = get(viewer, 'cart', {});
    const subtotal = get(cart, 'subtotal');

    const menu = (
      <ItemList
        viewer={viewer}
        handleMenuClick={this.handleMenuClick}
        style={{
          maxWidth: '400px',
          minWidth: '200px',
          boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
        }}
      />
    );

    return (
      <div
        id="cart-view"
        style={this.props.bodyStyle}
      >
        <Dropdown
          overlay={menu}
          placement="bottomRight"
          trigger={['hover', 'click']}
          onVisibleChange={this.handleVisibleChange}
          visible={this.state.visible}
          getPopupContainer={() => document.getElementById('cart-view')}
          destroyPopupOnHide
        >
          <div className="click-state small-cart">
            <Badge
              count={this.getTotalItem(cart)}
              offset={[10,7]}
              style={{
                fontSize: '10px',
                boxShadow: '0px 0px 0px transparent',
                backgroundColor: 'white',
                color: '#cb0000',
                fontWeight: '600',
              }}
            >
              <ShoppingCartOutlined style={{ fontSize: '25px', color: 'white' }} />
            </Badge>
            <br />
            <MediaQuery minWidth={301}>
              <span style={{ fontWeight: '600' }}>${(subtotal || 0).toFixed(2)}</span>
            </MediaQuery>
          </div>
        </Dropdown>
      </div>
    );
  }
}
export default createFragmentContainer(CartView, {
  viewer: graphql`
    fragment CartView_viewer on Customer {
      ...ItemList_viewer
      cart {
        id
        subtotal
        lines(first: 999) @connection(key: "CartView_lines") {
          edges {
            node {
              id
              name
              unitPrice
              quantity
              rowTotal
              unitDiscount
              unitSurcharge
            }
          }
        }
      }
    }
  `,
});
