import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { get } from 'lodash';
import { Link } from 'found';
import { SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Divider, Form } from 'antd';

import { sanitiseCharacters, slugify } from '~/helper';
import { renderOption, handleSearch } from '../product/ShippingQuote';
import { SEARCH_QUERY, groupByState, getStateName } from './StoreList';

const { Item: FormItem } = Form;

const titleStyle = {
  color: '#CB0000',
  fontWeight: '900',
  fontSize: '14px',
  lineHeight: '18px',
  marginBottom: '2px',
}

class StoreSearch extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      stores: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    router: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    const { viewer } = this.props;
    const stores = get(viewer, 'stores.edges', []);
    this.states = Object.keys(groupByState(stores)).sort();

    this.state = {
      dataSource: [],
      selected: null,
      lastInput: '',
    }
  }

  onSearch = (value) => {
    value = sanitiseCharacters(value);

    this.formRef.current.setFieldsValue({
      storeSearch: value
    });

    if (this.state.lastInput !== value) {
      handleSearch.call(this, 'dataSource', value);
    }

    this.setState({
      lastInput: value
    });
  }

  onSelect = (id, option) => {
    if (option && this.state.dataSource.length > 0) {
      const selected = get(option, 'item', null);

      this.setState({
        selected
      }, () => {
        const query = [selected.location, selected.postcode].join(" ").trim();

        this.goTo(query);
      });
    }
  }

  handleSubmit = (values) => {
    const { selected } = this.state;

    const v = get(values, 'storeSearch');
    const selectedId = get(selected, 'id', "");
    let query = null;

    if (v === selectedId.toString()) {
      query = `${selected.location} ${selected.postcode}`;
    } else {
      query = v;
    }

    this.goTo(query);
  }

  goTo = (query) => {
    if (query) {
      this.props.router.push({
        pathname: '/stores',
        search: `${SEARCH_QUERY}=${query}`,
      });
    }
  }

  render() {
    return (
      <div style={{ marginBottom: '5px' }}>
        <h3 style={titleStyle}>Find Your Nearest Store</h3>

        <div style={{ marginBottom: '0px' }}>
          {this.states.map((s, index) => {
            const url = `/stores/${slugify(s)}`;

            return (
              <div key={s} style={{ display: "inline" }}>
                <Link rel="nofollow" to={url}>{getStateName(s)}</Link>

                {this.states.length - 1 === index ? null : <Divider type="vertical" />}
              </div>
            )
          })}
        </div>

        <Form ref={this.formRef} style={{ display: 'flex' }} onFinish={this.handleSubmit}>
          <FormItem
            name="storeSearch"
            rules={[
              { required: false, message: 'Required' },
            ]}
            wrapperCol={{ xs: 24 }}
            style={{ width: "100%", margin: '0px' }}
          >
            <AutoComplete
              allowClear
              style={{ display: 'block', width: '100%' }}
              options={this.state.dataSource.map(renderOption)}
              onSearch={this.onSearch}
              onSelect={this.onSelect}
              dropdownStyle={{ maxHeight: '160px' }}
              placeholder={<small>Enter a suburb or postcode</small>}
            />
          </FormItem>
          <FormItem style={{margin: '0px'}}>
            <Button htmlType="submit" type="primary" style={{ display: 'block' }}><SearchOutlined /></Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}

export default createFragmentContainer(StoreSearch, {
  viewer: graphql`
    fragment StoreSearch_viewer on Customer {
      stores(first: 999) {
        edges {
          node {
            id
            state
          }
        }
      }
    }
  `,
});
