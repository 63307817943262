import React from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { CompleteRedirectOrderMutation } from '../mutations';
import { onCheckoutCompleted } from '../helper';

export default class Zippay extends React.Component {
  static redirect = function redirect(loc) {
    if (['checkoutId', 'result'].every(k => k in loc.query)) {
      if (['approved'].includes(loc.query.result)) {
        const values = {
          zipCheckoutId: loc.query.checkoutId,
          zipCheckoutState: loc.query.result,
          PaymentMethod: 'zip',
        };

        const onCompleted = onCheckoutCompleted.bind(this);

        CompleteRedirectOrderMutation.commit({
          // eslint-disable-next-line react/prop-types
          environment: this.props.relay.environment,
          variables: { input: values },
          // eslint-disable-next-line react/prop-types
          viewer: this.props.viewer,
          onCompleted,
          onError: (errors) => {
            message.destroy(); // dimiss loading message
            message.error(errors[0].message, 10);
            // eslint-disable-next-line react/no-unused-state
            this.setState({ loading: false });
          },
        });
      }
    }
  }

  static init = (form, submit) => {

    const fields = form.getFieldsValue();

    /*
     * When re-setting a password is present, the above guard is not enough to prevent order placing
     * as `checkoutPassword` field is removed.
     */
    if (fields.billing && Object.keys(fields.billing).length === 1 && fields.billing.email) {
      message.error('Please login to place your order')
      return;
    }

    // Form wasn't scrolling when theres a missing field
    form.validateFieldsAndScroll((err) => {
      if (!err) {
        submit();
      }
    })
  }

  static propTypes = {
    form: PropTypes.shape({
      setFieldsValue: PropTypes.func.isRequired,
      getFieldDecorator: PropTypes.func.isRequired,
    }).isRequired,
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div style={{display: 'none'}}>
        {getFieldDecorator('zipCheckoutId')(<div />)}
        {getFieldDecorator('zipCheckoutState')(<div />)}
      </div>
    );
  }
}
