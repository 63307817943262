import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import Helmet from '~/components/page/Helmet';
import { importScript } from '~/helper';

let country = 'au';
if (process.env.COUNTRY === 'NZ') {
  country = 'nz';
}

class ZipPage extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      cmsPage: PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    importScript("https://static.zip.co/lib/js/zm-widget-js/dist/zip-widget.min.js");
  }

  render() {
    const { viewer: { cmsPage: page }} = this.props;

    return (
      <div>
        <Helmet title={page.title} />
        <h1>{page.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: page.content }} />
        <div
          zm-asset="landingpage"
          data-env="production"
          data-zm-merchant="4441aa8d-aa9a-41ea-9d5f-19241c069a0d"
          zm-widget="inline"
          data-zm-region={country}
        />
      </div>
    );
  }
}

export default createFragmentContainer(ZipPage, {
  viewer: graphql`
    fragment ZipPage_viewer on Customer {
      cmsPage(urlSlug: $urlSlug) {
        title
        urlSlug
        content
      }
    }
  `,
});
