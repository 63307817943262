import React from 'react';
import { HttpError, Route } from 'found';
import { get } from 'lodash';
import { graphql } from 'react-relay';
import { routerRender } from '~/components/account/SideNav';

const { COUNTRY } = process.env;

const BASE_PATH = "/p";
let TOCROUTES = ["terms", "returns-refunds-repairs", "shipping", "points", "best-price-guarantee", "about-us"];

if (process.env.COUNTRY === 'NZ') {
  TOCROUTES = ["terms", "website-terms-of-use", "about-us"];
}

// Used for disable sticky header
export const getTocRoutes = () => {
  return TOCROUTES.map(route => `${BASE_PATH}/${route}`);
}

export const getTnCPath = (path) => {
  let paths = {
    "best-price-guarantee": "/p/best-price-guarantee",
    "points": "/p/points",
    "returns-refunds-repairs": "/p/returns-refunds-repairs",
    "shipping": "/p/shipping",
  };

  if (COUNTRY === 'NZ') {
    paths = {
      "best-price-guarantee": "/p/terms#best-prices-guarantee",
      "points": "/p/terms#bonus-points",
      "returns-refunds-repairs": "/p/terms#returns-policy",
      "shipping": "/p/terms#delivery",
    };
  }

  return get(paths, path);
}

const Covid19PageQuery = graphql`
  query routes_Covid19Page_Query($filterBy: [FilterBy]) {
    viewer {
      ...Covid19Page_viewer
      ...Covid19Page_viewer @relay(mask: false)
    }
  }
`;

const CmsPageQuery = graphql`
  query routes_CmsPage_Query($urlSlug: String!) {
    viewer {
      ...CmsPage_viewer
      ...CmsPage_viewer @relay(mask: false)
    }
  }
`;

const FeedbackPageQuery = graphql`
  query routes_FeedbackPage_Query($urlSlug: String!) {
    viewer {
      ...FeedbackPage_viewer
      ...FeedbackPage_viewer @relay(mask: false)
    }
  }
`;
const CareerPageQuery = graphql`
  query routes_CareerPage_Query($urlSlug: String!) {
    viewer {
      ...CareerPage_viewer
      ...CareerPage_viewer @relay(mask: false)
    }
  }
`;
const CustomerApplicationsQuery = graphql`
  query routes_CustomerApplications_Query($urlSlug: String!) {
    viewer {
      ...CmsPage_viewer
      ...CmsPage_viewer @relay(mask: false)
      ...SideNav_viewer
      ...SideNav_viewer @relay(mask: false)
    }
  }
`;
const CustomerOrderFormQuery = graphql`
  query routes_CustomerOrderForm_Query($urlSlug: String!) {
    viewer {
      ...CustomerOrderForm_viewer
      ...CustomerOrderForm_viewer @relay(mask: false)
    }
  }
`;
const SupplierFormQuery = graphql`
  query routes_SupplierForm_Query($urlSlug: String!) {
    viewer {
      ...SupplierForm_viewer
      ...SupplierForm_viewer @relay(mask: false)
    }
  }
`;
const ZipPageQuery = graphql`
  query routes_ZipPage_Query($urlSlug: String!) {
    viewer {
      ...ZipPage_viewer
      ...ZipPage_viewer @relay(mask: false)
    }
  }
`;

const ThreeMPageQuery = graphql`
  query routes_ThreeMPage_Query($urlSlug: String!) {
    viewer {
      ...ThreeMPage_viewer
      ...ThreeMPage_viewer @relay(mask: false)
    }
  }
`;

const TocPageQuery = graphql`
  query routes_TocPage_Query($urlSlug: String!) {
    viewer {
      ...TocPage_viewer
      ...TocPage_viewer @relay(mask: false)
    }
  }
`;

const MacnaughtPageQuery = graphql`
  query routes_MacnaughtPage_Query($urlSlug: String!) {
    viewer {
      ...MacnaughtPage_viewer
      ...MacnaughtPage_viewer @relay(mask: false)
    }
  }
`;

const SptoolsPageQuery = graphql`
  query routes_SptoolsPage_Query($urlSlug: String!) {
    viewer {
      ...SptoolsPage_viewer
      ...SptoolsPage_viewer @relay(mask: false)
    }
  }
`;

const routes = [
  <Route
    key={BASE_PATH}
    path={BASE_PATH}
    getComponent={() => (
      import(/* webpackChunkName: "Cms" */'~/components/cms').then(module => module.Cms)
        .catch(() => {
          window.location.reload();
        })
    )}
  >
    <Route
      path="/3m-competition"
      getComponent={() => (
        import(/* webpackChunkName: "ThreeMPage" */'~/components/cms').then(module => module.ThreeMPage)
          .catch(() => {
            window.location.reload();
          })
      )}
      render={({ resolving, Component, props }) => {
        if (resolving && Component && props) {
          if (get(props, 'viewer.cmsPage')) {
            return <Component {...props} />;
          }

          throw new HttpError(404, props);
        }
        return undefined;
      }}
      query={ThreeMPageQuery}
      prepareVariables={() => ({ urlSlug: '3m-competition' })}
    />
    <Route
      path="/macnaught"
      getComponent={() => (
        import(/* webpackChunkName: "MacnaughtPage" */'~/components/cms').then(module => module.MacnaughtPage)
          .catch(() => {
            window.location.reload();
          })
      )}
      render={({ resolving, Component, props }) => {
        if (resolving && Component && props) {
          if (get(props, 'viewer.cmsPage')) {
            return <Component {...props} />;
          }

          throw new HttpError(404, props);
        }
        return undefined;
      }}
      query={MacnaughtPageQuery}
      prepareVariables={() => ({ urlSlug: 'macnaught' })}
    />
    <Route
      path="/sptools"
      getComponent={() => (
        import(/* webpackChunkName: "SptoolsPage" */'~/components/cms').then(module => module.SptoolsPage)
          .catch(() => {
            window.location.reload();
          })
      )}
      render={({ resolving, Component, props }) => {
        if (resolving && Component && props) {
          if (get(props, 'viewer.cmsPage')) {
            return <Component {...props} />;
          }

          throw new HttpError(404, props);
        }
        return undefined;
      }}
      query={SptoolsPageQuery}
      prepareVariables={() => ({ urlSlug: 'sptools' })}
    />
    <Route
      path="/covid-19"
      getComponent={() => (
        import(/* webpackChunkName: "Covid19Page" */'~/components/cms').then(module => module.Covid19Page)
          .catch(() => {
            window.location.reload();
          })
      )}
      render={({ resolving, Component, props }) => {
        if (resolving && Component && props) {
          if (get(props, 'viewer.cmsPage')) {
            return <Component {...props} />;
          }

          throw new HttpError(404, props);
        }
        return undefined;
      }}
      query={Covid19PageQuery}
      prepareVariables={() => {
        const filterBy = [{
          field: 'name',
          filter: '[COVID-19]',
          filterType: 'text',
          type: 'contains',
        }];

        return { urlSlug: 'covid-19', filterBy };
      }}
    />
    <Route
      path="/careers"
      getComponent={() => (
        import(/* webpackChunkName: "CareerPage" */'~/components/cms').then(module => module.CareerPage)
          .catch(() => {
            window.location.reload();
          })
      )}
      query={CareerPageQuery}
      render={({ resolving, Component, props }) => {
        if (resolving && Component && props) {
          if (get(props, 'viewer.cmsPage')) {
            return <Component {...props} />;
          }

          throw new HttpError(404, props);
        }
        return undefined;
      }}
      prepareVariables={() => ({ urlSlug: 'careers' })}
    />
    <Route
      path="/feedback"
      getComponent={() => (
        import(/* webpackChunkName: "FeedbackPage" */'~/components/cms').then(module => module.FeedbackPage)
          .catch(() => {
            window.location.reload();
          })
      )}
      render={({ resolving, Component, props }) => {
        if (resolving && Component && props) {
          if (get(props, 'viewer.cmsPage')) {
            return <Component {...props} />;
          }

          throw new HttpError(404, props);
        }
        return undefined;
      }}
      query={FeedbackPageQuery}
      prepareVariables={() => ({ urlSlug: 'feedback' })}
    />
    <Route
      path="/customer-applications"
      getComponent={() => (
        import(/* webpackChunkName: "CmsPage" */'~/components/cms').then(module => module.CmsPage)
          .catch(() => {
            window.location.reload();
          })
      )}
      render={routerRender}
      query={CustomerApplicationsQuery}
      prepareVariables={() => ({ urlSlug: 'customer-applications' })}
    />
    <Route
      path="/customer-order-form"
      getComponent={() => (
        import(/* webpackChunkName: "CustomerOrderForm" */'~/components/cms').then(module => module.CustomerOrderForm)
          .catch(() => {
            window.location.reload();
          })
      )}
      render={({ resolving, Component, props }) => {
        if (resolving && Component && props) {
          if (get(props, 'viewer.cmsPage')) {
            return <Component {...props} />;
          }

          throw new HttpError(404, props);
        }
        return undefined;
      }}
      query={CustomerOrderFormQuery}
      prepareVariables={() => ({ urlSlug: 'customer-order-form' })}
    />
    <Route
      path="/supplier"
      getComponent={() => (
        import(/* webpackChunkName: "SupplierForm" */'~/components/cms').then(module => module.SupplierForm)
          .catch(() => {
            window.location.reload();
          })
      )}
      render={({ resolving, Component, props }) => {
        if (resolving && Component && props) {
          if (get(props, 'viewer.cmsPage')) {
            return <Component {...props} />;
          }

          throw new HttpError(404, props);
        }
        return undefined;
      }}
      query={SupplierFormQuery}
      prepareVariables={() => ({ urlSlug: 'supplier-form' })}
    />
    <Route
      path={`/(${TOCROUTES.join("|")})`}
      getComponent={() => (
        import(/* webpackChunkName: "TocPage" */'~/components/cms').then(module => module.TocPage)
          .catch(() => {
            window.location.reload();
          })
      )}
      render={({ resolving, Component, props }) => {
        if (resolving && Component && props) {
          if (get(props, 'viewer.cmsPage')) {
            return <Component {...props} key={props.viewer.cmsPage.urlSlug} />;
          }

          throw new HttpError(404, props);
        }
        return undefined;
      }}
      query={TocPageQuery}
      prepareVariables={(params) => ({ urlSlug: params[0] })}
    />
    <Route
      path="/zip"
      getComponent={() => (
        import(/* webpackChunkName: "ZipPage" */'~/components/cms').then(module => module.ZipPage)
          .catch(() => {
            window.location.reload();
          })
      )}
      render={({ resolving, Component, props }) => {
        if (resolving && Component && props) {
          if (get(props, 'viewer.cmsPage')) {
            return <Component {...props} />;
          }

          throw new HttpError(404, props);
        }
        return undefined;
      }}
      query={ZipPageQuery}
      prepareVariables={() => ({ urlSlug: 'zip' })}
    />
    <Route
      path="/:urlSlug"
      getComponent={() => (
        import(/* webpackChunkName: "CmsPage" */'~/components/cms').then(module => module.CmsPage)
          .catch(() => {
            window.location.reload();
          })
      )}
      query={CmsPageQuery}
      render={({ resolving, Component, props }) => {
        if (resolving && Component && props) {
          if (get(props, 'viewer.cmsPage')) {
            return <Component {...props} />;
          }

          throw new HttpError(404, props);
        }
        return undefined;
      }}
    />
  </Route>
];

export default routes;
